.root {
  text-decoration: none;
  color: var(--tweet-font-color-secondary);
  font-size: 0.9375rem;
  line-height: 1.25rem;
  margin-bottom: 0.25rem;
  overflow-wrap: break-word;
  white-space: pre-wrap;
}
.root:hover {
  text-decoration-thickness: 1px;
  text-decoration-line: underline;
}
