.root {
  margin-top: 0.75rem;
  overflow: hidden;
  position: relative;
}
.rounded {
  border: var(--tweet-border);
  border-radius: 12px;
}
.mediaWrapper {
  display: grid;
  grid-auto-rows: 1fr;
  gap: 2px;
  height: 100%;
  width: 100%;
}
.grid2Columns {
  grid-template-columns: repeat(2, 1fr);
}
.grid3 > a:first-child {
  grid-row: span 2;
}
.grid2x2 {
  grid-template-rows: repeat(2, 1fr);
}
.mediaContainer {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.mediaLink {
  text-decoration: none;
  outline-style: none;
}
.skeleton {
  padding-bottom: 56.25%;
  width: 100%;
  display: block;
}
.image {
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  height: 100%;
  width: 100%;
  margin: 0;
  object-fit: cover;
  object-position: center;
}
