.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 0.75rem;
}
.root > h3 {
  font-size: 1.25rem;
  margin-bottom: 0.5rem;
}
