.root {
  font-weight: inherit;
  color: var(--tweet-color-blue-secondary);
  text-decoration: none;
  cursor: pointer;
}
.root:hover {
  text-decoration-thickness: 1px;
  text-decoration-line: underline;
}
